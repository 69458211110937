import IChatMessage from "../types/interfaces/IChatMessage";
import { Model } from "../types/enums/Model";
import { encodingForModel } from "js-tiktoken";

export default function trimConversationHistory(
  conversationHistory: IChatMessage[],
  model: Model,
  popupCallback: (s: string) => void = () => {},
) {
  let tokenLimit = 4_000;
  switch (model) {
    case Model.Claude2: {
      tokenLimit = 100_000;
      break;
    }
    case Model.Claude21: {
      tokenLimit = 180_000;
      break;
    }
    case Model.CohereCommand: {
      tokenLimit = 100_000;
      break;
    }
    case Model.GPT4Turbo: {
      tokenLimit = 124_000;
      break;
    }
    case Model.GPT4o: {
      tokenLimit = 124_000;
      break;
    }
    case Model.CodeLlama: {
      tokenLimit = 100_000;
      break;
    }
    case Model.Llama70b: {
      tokenLimit = 4_096;
      break;
    }
    case Model.Mistral7b: {
      tokenLimit = 8_000;
      break;
    }
    case Model.Gemini: {
      tokenLimit = 1_000_000;
      break;
    }
  }
  const encoding = encodingForModel("gpt-4-turbo-preview");
  let encoded = encoding.encode(JSON.stringify(conversationHistory));
  if (encoded.length > tokenLimit * 0.9) {
    popupCallback(
      "This conversation is being trimmed to fit the context window. Consider selecting a different model.",
    );
  }
  while (encoded.length > tokenLimit * 0.9) {
    conversationHistory = conversationHistory.filter((message, index) => {
      return index !== 1;
    });
    encoded = encoding.encode(JSON.stringify(conversationHistory));
  }
  return conversationHistory;
}
