export enum Model {
  GPT4Turbo = "gpt-4-turbo",
  GPT4o = "gpt-4o",
  Claude2 = "claude-2",
  Claude21 = "claude-2.1",
  Llama70b = "llama-70b",
  CodeLlama = "codellama-34b",
  Mistral7b = "mistral-7b",
  CohereCommand = "command",
  Dalle3 = "dall-e-3",
  Gemini = "gemini",
}
