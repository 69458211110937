import IChatMessage from "../types/interfaces/IChatMessage";
import { Typography, Box, Alert } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CodeBlock from "./CodeBlock";
import { useContext } from "react";
import { UserContext } from "./ActiveConversationWindow";
import ChatImage from "./ChatImage";

export type ChatMessageProps = {
  message: IChatMessage;
  fromUser: boolean;
};

export default function ChatMessage({ message, fromUser }: ChatMessageProps) {
  const userName = useContext(UserContext);

  let splitMessage = message.content.split("===== ATTACHED FILE BELOW======");
  let cleanedMessage = splitMessage[0];
  let attachedFileContent = splitMessage.length > 1 ? splitMessage[1] : "";

  let attachmentElement = <></>;
  if (attachedFileContent !== "") {
    attachmentElement = (
      <details>
        <Typography variant="overline" component="summary">
          Plain Text from Attached File
        </Typography>
        {attachedFileContent}
      </details>
    );
  }

  let bodyElement = <Alert severity="info">This message is blank.</Alert>;
  if (cleanedMessage !== "") {
    bodyElement = (
      <ReactMarkdown
        className="markdown"
        remarkPlugins={[remarkGfm]}
        // @ts-ignore
        components={{ pre: CodeBlock, img: ChatImage }}
      >
        {cleanedMessage}
      </ReactMarkdown>
    );
  }

  return (
    <Box sx={{ padding: 0 }}>
      <Typography variant="button" color="primary">
        {fromUser ? userName.userName : message.model ?? "Assistant"}
      </Typography>
      <Typography variant="body1" component={"span"}>
        {bodyElement}
        {attachmentElement}
      </Typography>
    </Box>
  );
}
