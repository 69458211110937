import IChatMessage from "../types/interfaces/IChatMessage";
import { Model } from "../types/enums/Model";
import IGatewayAPIRequest from "../types/interfaces/IGatewayAPIRequest";
import { AuthProvider } from "../types/enums/AuthProvider";
import convertGatewayRequest from "./convertGatewayRequest";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import trimConversationHistory from "./trimConversationHistory";
import cleanMessages from "./cleanMessages";

interface FetchPromiseWithAbort extends Promise<Response> {
  abort: AbortController;
}

export default function sendGatewayRequest(
  chatHistory: IChatMessage[],
  model: Model,
  accessToken: OidcAccessToken,
  rag: boolean = false,
  pii: boolean = false,
  popupCallback: (s: string) => void = () => {},
): FetchPromiseWithAbort {
  // Use the extended type here
  const host: string =
    process.env.REACT_APP_LLMGATEWAY_HOST ?? "http://localhost:9090";
  const skeleton_key: string =
    process.env.REACT_APP_LLMGATEWAY_SKELETONKEY ?? "";
  const trimmedHistory = trimConversationHistory(
    chatHistory,
    model,
    popupCallback,
  );
  const gateway_api_request: IGatewayAPIRequest = {
    messages: cleanMessages(trimmedHistory),
    model: model,
    rag: rag,
    stream: true,
    authProvider: AuthProvider.OneLogin,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  // Create an instance of AbortController
  const abortController = new AbortController();
  const { signal } = abortController;

  // Fetch request with signal option
  const fetchPromise = fetch(`${host}/chat/completions`, {
    method: "POST",
    body: JSON.stringify(convertGatewayRequest(gateway_api_request)),
    headers: headers,
    signal, // Pass signal option
  }) as FetchPromiseWithAbort; // Cast to the extended type

  // Attach abort function to the returned promise
  fetchPromise.abort = abortController;
  return fetchPromise;
}

export async function nameConversation(
  chatHistory: IChatMessage[],
  model: Model,
  accessToken: OidcAccessToken,
) {
  const host: string =
    process.env.REACT_APP_LLMGATEWAY_HOST ?? "http://localhost:9090";
  const skeleton_key: string =
    process.env.REACT_APP_LLMGATEWAY_SKELETONKEY ?? "";
  const gateway_api_request: IGatewayAPIRequest = {
    messages: chatHistory,
    model: model,
    rag: false,
    stream: false,
    authProvider: AuthProvider.OneLogin,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };
  let resp = await fetch(`${host}/name_conversation`, {
    method: "POST",
    body: JSON.stringify(convertGatewayRequest(gateway_api_request)),
    headers: headers,
  });
  
  return resp;
}



