import { IconButton, Menu, MenuItem, Stack, TextField } from "@mui/material";
import {
  Cancel,
  Download,
  MenuSharp,
  Send,
  Settings,
  Upload,
} from "@mui/icons-material";
import IChatMessage from "../types/interfaces/IChatMessage";
import React, { ChangeEvent, Fragment, useState } from "react";
import { MessageRole } from "../types/enums/MessageRole";
import { KeyboardEvent } from "react";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import FileAttachment from "./FileAttachment";
import identifyAndParseUploadedFile from "../utilities/identifyAndParseUploadedFile";
import { v4 } from "uuid";

export type ComposeAreaProps = {
  onSend: (message: IChatMessage) => void;
  enabled: boolean;
  isSending: boolean;
  onConversationSettingsOpen: () => void;
  onExportTranscript: () => void;
  onError: (notificationText: string) => void;
  conversationId: string;
  cancelStream: () => void;
};

export default function ComposeArea({
  onSend,
  enabled,
  isSending,
  onConversationSettingsOpen,
  onExportTranscript,
  onError,
  conversationId,
  cancelStream,
}: ComposeAreaProps) {
  const [text, setText] = useState("");
  const [parsedFileText, setParsedFileText] = useState<string | undefined>();
  const [parsedFileName, setParsedFileName] = useState<string | undefined>();

  const handleMessageSubmit = () => {
    if (text.trim() === "") {
      onError("You cannot send a blank message.");
      return;
    }
    const newId = v4();
    if (parsedFileText !== undefined) {
      onSend({
        id: newId,
        role: MessageRole.User,
        content: text + "\n===== ATTACHED FILE BELOW======\n" + parsedFileText,
        conversationId: conversationId,
        updated: new Date(),
        model: null,
      });
    } else {
      onSend({
        id: newId,
        role: MessageRole.User,
        content: text,
        conversationId: conversationId,
        updated: new Date(),
        model: null,
      });
    }
    setText("");
    setParsedFileText(undefined);
    setParsedFileName(undefined);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey && enabled) {
      e.preventDefault();
      handleMessageSubmit();
    }
  };

  // @ts-ignore
  const closePopup = (ps: PopupState) => {
    ps.close();
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      const fileText = identifyAndParseUploadedFile(file);
      if (fileText === undefined) {
        onError("Sorry, that file type isn't currently supported.");
      } else {
        fileText.then((text) => {
          setParsedFileText(text);
          setParsedFileName(file.name);
        });
      }
    }
  };

  const handleDeleteFile = () => {
    setParsedFileText(undefined);
    setParsedFileName(undefined);
  };

  return (
    <Stack
      direction="row"
      useFlexGap={true}
      gap="0.5em"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "sticky",
        padding: "0.5em",
        width: "100%",
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "100%",
        bottom: 0,
      }}
    >
      <PopupState
        parentPopupState={null}
        variant="popover"
        popupId="message-settings-popover"
        disableAutoFocus={false}
      >
        {(popupState) => (
          <Fragment>
            <IconButton
              sx={{ height: "100%", alignSelf: "center" }}
              size="large"
              {...bindTrigger(popupState)}
            >
              <MenuSharp />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem component="label">
                <Upload />
                &nbsp; Attach a file
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    handleFileUpload(e);
                    closePopup(popupState);
                  }}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onExportTranscript();
                  closePopup(popupState);
                }}
              >
                <Download />
                &nbsp; Download conversation transcript
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onConversationSettingsOpen();
                  closePopup(popupState);
                }}
              >
                <Settings />
                &nbsp; Change conversation settings...
              </MenuItem>
            </Menu>
          </Fragment>
        )}
      </PopupState>

      <TextField
        multiline={true}
        value={text}
        onKeyDown={handleKeyDown}
        fullWidth={true}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        maxRows={5}
        placeholder="Write a message"
        sx={{ maxWidth: "960px" }}
      />
      {parsedFileName !== undefined && parsedFileText !== undefined ? (
        <FileAttachment
          onDeleteFile={handleDeleteFile}
          fileName={parsedFileName}
        ></FileAttachment>
      ) : (
        <></>
      )}
      {isSending ? (
        <IconButton
          content="cancel"
          size="large"
          onClick={cancelStream}
          sx={{ height: "100%", alignSelf: "center" }}
        >
          <Cancel />
        </IconButton>
      ) : (
        <IconButton
          content="send"
          disabled={isSending || !enabled}
          size="large"
          onClick={handleMessageSubmit}
          sx={{ height: "100%", alignSelf: "center" }}
        >
          <Send />
        </IconButton>
      )}
    </Stack>
  );
}
