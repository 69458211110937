import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import pdfToText from "react-pdftotext";
import readXlsxFile from "read-excel-file";

export default function identifyAndParseUploadedFile(file: File) {
  let fileName = file.name;
  if (fileName.endsWith("docx") || fileName.endsWith("pptx")) {
    return file.arrayBuffer().then((data) => {
      const zip = new PizZip(data);
      const doc = new Docxtemplater(zip, { linebreaks: true });
      return Promise.resolve(doc.getFullText());
    });
  } else if (fileName.endsWith("pdf")) {
    return Promise.resolve(pdfToText(file));
  } else if (fileName.endsWith("xlsx")) {
    return readXlsxFile(file).then((rows) => {
      return Promise.resolve(JSON.stringify({ rows: rows }));
    });
  } else if (
    fileName.endsWith("txt") ||
    fileName.endsWith("csv") ||
    fileName.endsWith("json") ||
    fileName.endsWith("py") ||
    fileName.endsWith("java") ||
    fileName.endsWith("c")
  ) {
    return file.text();
  }
}
