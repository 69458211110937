import IChatMessage from "../types/interfaces/IChatMessage";
import { Alert, Box, Divider, Stack, Typography } from "@mui/material";
import { MessageRole } from "../types/enums/MessageRole";
import ChatMessage from "./ChatMessage";
import IncomingMessage, { IncomingMessageStream } from "./IncomingMessage";
import AssistantMessageActionBar from "./AssistantMessageActionBar";
import copy from "copy-to-clipboard";
import getDefaults from "../utilities/defaults";
import ReactMarkdown from "react-markdown";
import downloadText from "../utilities/downloadText";
import { Model } from "../types/enums/Model";

export type ChatHistoryProps = {
  chatMessages: IChatMessage[];
  stream?: IncomingMessageStream;
  isSending: boolean;
  onRegenerateMessage: () => void;
  scrollCallback: () => void;
  heightOffset: number;
  model: Model | null;
};

export default function ChatHistory({
  chatMessages,
  stream,
  isSending,
  onRegenerateMessage,
  scrollCallback,
  heightOffset,
  model,
}: ChatHistoryProps) {
  if (chatMessages.length === 0) {
    return (
      <Alert severity="info" sx={{ m: "auto" }}>
        Send a message to begin the conversation.
      </Alert>
    );
  }

  const handleCopyMessage = () => {
    copy(chatMessages[chatMessages.length - 1].content);
  };

  let systemMessage = <div />;
  if (chatMessages.length > 0 && chatMessages[0].role == MessageRole.System) {
    systemMessage = (
      <Box
        display="flex"
        flexDirection="column"
        justifyItems="start"
        width="100%"
        maxWidth="min(960px, 100%)"
      >
        <details>
          <Typography variant="overline" component="summary">
            System Prompt{" "}
            {chatMessages[0].content === getDefaults().defaultSystemPrompt ? (
              <></>
            ) : (
              <span>(customized)</span>
            )}
          </Typography>
          <ReactMarkdown className="markdown">
            {chatMessages[0].content}
          </ReactMarkdown>
          <Divider />
        </details>
      </Box>
    );
  }

  let handleDownloadMessage = () => {
    const lastMessage = chatMessages[chatMessages.length - 1];
    downloadText("exportedChatMessage.txt", lastMessage.content);
  };

  let incomingMessageElement = <div />;
  if (isSending) {
    incomingMessageElement = (
      <IncomingMessage
        stream={stream}
        scrollCallback={scrollCallback}
        model={model}
      ></IncomingMessage>
    );
  } else if (
    chatMessages[chatMessages.length - 1].role === MessageRole.Assistant
  ) {
    incomingMessageElement = (
      <AssistantMessageActionBar
        onRegenerateMessage={onRegenerateMessage}
        onCopyMessage={handleCopyMessage}
        onMessageDownload={handleDownloadMessage}
      />
    );
  }
  return (
    <Stack
      spacing={{ xs: 1, sm: 2 }}
      sx={{
        padding: "0.5em",
        boxSizing: "border-box",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: `calc(100vh - ${heightOffset}px)`,
      }}
      direction="column"
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      maxWidth={960}
      width="100%"
    >
      {systemMessage}
      {chatMessages
        .filter((m) => m.role !== MessageRole.System)
        .map((m, index, a) => (
          <Box key={index} sx={{ padding: 0, width: "100%" }}>
            <ChatMessage message={m} fromUser={m.role === MessageRole.User} />
            {index === a.length - 1 ? (
              <></>
            ) : (
              <Divider sx={{ width: "100%" }} />
            )}
          </Box>
        ))}
      {incomingMessageElement}
    </Stack>
  );
}
