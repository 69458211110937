import { Stack, IconButton } from "@mui/material/";
import { ContentPaste, Download, Refresh } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

export type AssistantMessageActionBarProps = {
  onRegenerateMessage: () => void;
  onCopyMessage: () => void;
  onMessageDownload: () => void;
};

export default function AssistantMessageActionBar({
  onRegenerateMessage,
  onCopyMessage,
  onMessageDownload,
}: AssistantMessageActionBarProps) {
  return (
    <Box maxWidth="960px" width="100%">
      <Stack direction="row">
        <Tooltip title="Try this one again">
          <IconButton onClick={onRegenerateMessage}>
            <Refresh />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy this message to the clipboard">
          <IconButton onClick={onCopyMessage}>
            <ContentPaste />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download a copy of this message">
          <IconButton onClick={onMessageDownload}>
            <Download />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}
