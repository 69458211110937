import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import IConversationSettings from "../types/interfaces/IConversationSettings";
import { Model } from "../types/enums/Model";

export type ConversationSettingsProps = {
  open: boolean;
  onClose: () => void;
  onSave: (newSettings: IConversationSettings) => void;
  currentModel: Model;
  currentSystemPrompt: string;
  currentPii: boolean;
  currentRag: boolean;
};

export default function ConversationSettings({
  open,
  onClose,
  onSave,
  currentModel,
  currentSystemPrompt,
  currentPii,
  currentRag,
}: ConversationSettingsProps) {
  const [systemPrompt, setSystemPrompt] = useState<string>(currentSystemPrompt);
  const [model, setModel] = useState<Model>(currentModel);
  const [rag, setRag] = useState<boolean>(currentRag);
  const [pii, setPii] = useState<boolean>(currentPii);

  const handleClose = () => {
    setPii(currentPii);
    setRag(currentRag);
    setModel(currentModel);
    setSystemPrompt(currentSystemPrompt);
    onClose();
  };

  useEffect(() => {
    setModel(currentModel);
    setRag(currentRag);
    setPii(currentPii);
    setSystemPrompt(currentSystemPrompt);
  }, [currentSystemPrompt, currentPii, currentRag, currentModel]);

  const handleSave = () => {
    onSave({
      systemPrompt: systemPrompt,
      model: model,
      pii: pii,
      rag: rag,
    });
    onClose();
  };

  const handleSystemPromptChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSystemPrompt(event.target.value as string);
  };

  const handleModelChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setModel(event.target.value as Model);
  };

  const handlePiiChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPii((prev) => !prev);
  };

  const handleRagChange = (event: ChangeEvent<{ checked: boolean }>) => {
    setRag((prev) => !prev);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <Card sx={{ padding: "1em", width: "100%" }}>
        <Box>
          <FormGroup
            sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
          >
            <TextField
              multiline={true}
              maxRows={10}
              value={systemPrompt}
              label={"System Prompt"}
              onChange={handleSystemPromptChange}
            />
            <FormControl>
              <InputLabel id="modelSelectLabel">Model</InputLabel>
              <Select
                labelId="modelSelectLabel"
                // @ts-ignore
                value={model}
                label="Model"
                onChange={handleModelChange}
              >
                {Object.values(Model).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleRagChange}
                  name={"RAG"}
                  checked={rag}
                />
              }
              label={"Use stored documents to inform responses (RAG)"}
            />
          </FormGroup>
          <Box display="flex" sx={{ mt: "1em" }}>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      </Card>
    </Dialog>
  );
}
