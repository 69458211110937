import IChatMessage from "../types/interfaces/IChatMessage";
import { Model } from "../types/enums/Model";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import trimConversationHistory from "./trimConversationHistory";
import IGatewayAPIRequest from "../types/interfaces/IGatewayAPIRequest";
import cleanMessages from "./cleanMessages";
import { AuthProvider } from "../types/enums/AuthProvider";
import convertGatewayRequest from "./convertGatewayRequest";

export type ImageResponse = {
  revised_prompt: string;
  b64_json: string;
};

interface FetchPromiseWithAbort extends Promise<Response> {
  abort: AbortController;
}

export default function sendImageGatewayRequest(
  chatHistory: IChatMessage[],
  model: Model,
  accessToken: OidcAccessToken,
  rag: boolean = false,
  pii: boolean = false,
  popupCallback: (s: string) => void = () => {},
): FetchPromiseWithAbort {
  // Use the extended type here
  const host: string =
    process.env.REACT_APP_LLMGATEWAY_HOST ?? "http://localhost:9090";
  const skeleton_key: string =
    process.env.REACT_APP_LLMGATEWAY_SKELETONKEY ?? "";
  const prompt = chatHistory[chatHistory.length - 1];
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  // Create an instance of AbortController
  const abortController = new AbortController();
  const { signal } = abortController;

  // Fetch request with signal option
  const fetchPromise = fetch(`${host}/images/generations`, {
    method: "POST",
    body: JSON.stringify({
      model: model,
      prompt: prompt.content,
      response_format: "url",
      size: "1024x1024",
    }),
    headers: headers,
    signal, // Pass signal option
  }) as FetchPromiseWithAbort;

  // Attach abort function to the returned promise
  fetchPromise.abort = abortController;

  return fetchPromise;
}
