import useLocalStorage from "../hooks/useLocalStorage";
import IChatMessage from "../types/interfaces/IChatMessage";
import { Alert, Box, IconButton, Snackbar, Toolbar } from "@mui/material";
import ComposeArea, { ComposeAreaProps } from "./ComposeArea";
import ChatHistory, { ChatHistoryProps } from "./ChatHistory";
import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import sendGatewayRequest from "../utilities/sendGatewayRequest";
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { Model } from "../types/enums/Model";
import readMessageStream from "../utilities/readMessageStream";
import { MessageRole } from "../types/enums/MessageRole";
import ConversationSettings from "./ConversationSettings";
import IConversationSettings from "../types/interfaces/IConversationSettings";
import getDefaults from "../utilities/defaults";
import { Close } from "@mui/icons-material";
import { DarkModeEnabledContext, OidcConfigurationNameContext } from "../index";
import { useLiveQuery } from "dexie-react-hooks";
import { v4 as uuid4 } from "uuid";
import { db } from "../database/db";
import sendImageGatewayRequest from "../utilities/sendImageGatewayRequest";
import { withTransaction } from "@elastic/apm-rum-react";
import { apm } from "@elastic/apm-rum";
import { nameConversation } from "../utilities/sendGatewayRequest";

export const UserContext = createContext<{ userName: string; roles: string[] }>(
  { userName: "You", roles: [] },
);

export default withTransaction(
  "ActiveConversationWindow",
  "component",
)(ActiveConversationWindow);

export type ActiveConversationWindowProps = {
  conversationId: string | null | undefined;
  isSending: boolean;
  onMessageSending: () => void;
  onMessageSent: () => void;
  onTranscriptDownloaded: (history: IChatMessage[]) => void;
  clearSending: () => void;
};

function ActiveConversationWindow({
  conversationId,
  isSending,
  onMessageSending,
  onMessageSent,
  onTranscriptDownloaded,
  clearSending,
}: ActiveConversationWindowProps) {
  const chatMessages = useLiveQuery(
    () =>
      db.chatMessage
        .where("conversationId")
        .equals(conversationId ?? "")
        .sortBy("updated"),
    [conversationId],
  );

  const [conversationSettings, setConversationSettings] =
    useLocalStorage<IConversationSettings>("chatSettings-" + conversationId, {
      model: Model.GPT4o,
      systemPrompt: getDefaults().defaultSystemPrompt,
      rag: false,
      pii: false,
    });
  const [stream, setStream] = useState<ChatHistoryProps["stream"]>();
  const [conversationSettingsOpen, setConversationSettingsOpen] =
    useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string>("No notification");
  const darkMode = useContext(DarkModeEnabledContext);
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const abortControllerRef = useRef<AbortController | null>(null);
  const abort = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);
  const streamedContentRef = useRef<string>("");
  const accessToken = useOidcAccessToken(oidcConfigurationName);
  const oidcUser = useOidcUser(oidcConfigurationName);
  let userName = "You";
  let roles: string[] = [];

  useEffect(() => {
    userName =
      oidcUser?.oidcUser?.given_name ??
      accessToken?.accessTokenPayload.given_name ??
      "You";
    roles =
      oidcUser?.oidcUser?.groups ?? accessToken?.accessTokenPayload.roles ?? [];
  }, [oidcConfigurationName]);

  const containerRef = useRef<HTMLDivElement>(null);

  const updateSystemPrompt = useCallback(
    (newPrompt: string) => {
      (async function () {
        if (conversationId === null || conversationId === undefined) {
          return;
        }
        try {
          const systemMessage = await db.chatMessage.get({
            role: MessageRole.System,
            conversationId: conversationId,
          });
          if (systemMessage === undefined) {
            console.error(
              `No such message: System message for ${conversationId}`,
            );
            return;
          }
          systemMessage.content = newPrompt;
          await db.chatMessage.put(systemMessage, systemMessage.id);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    [conversationId],
  );

  useEffect(() => {
    updateSystemPrompt(conversationSettings.systemPrompt);
  }, [conversationSettings.systemPrompt, updateSystemPrompt]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversationId]);

  const updateStreamedMessageText = (chunk: string) => {
    streamedContentRef.current = streamedContentRef.current += chunk;
  };

  const handleRegenerateMessage = async () => {
    let tr = apm.startTransaction("regenerate", "llm.message");
    let sp = tr?.startSpan("message");
    streamedContentRef.current = "";
    const lastAssistantMessageQuery = await db.chatMessage.filter(
      (m) =>
        m.conversationId === conversationId && m.role === MessageRole.Assistant,
    );
    const lastAssistantMessage = (
      await lastAssistantMessageQuery.sortBy("updated")
    ).pop();
    if (lastAssistantMessage === undefined) {
      return;
    }
    await db.chatMessage.delete(lastAssistantMessage.id);
    const assistantMessagePromise =
      conversationSettings.model !== Model.Dalle3
        ? sendGatewayRequest(
            (chatMessages ?? []).slice(0, -1),
            conversationSettings.model,
            accessToken,
            conversationSettings.rag,
            conversationSettings.pii,
            handleSnackbarOpen,
          )
        : sendImageGatewayRequest(
            (chatMessages ?? []).slice(0, -1),
            conversationSettings.model,
            accessToken,
            conversationSettings.rag,
            conversationSettings.pii,
            handleSnackbarOpen,
          );
    abortControllerRef.current = assistantMessagePromise.abort;
    const newId = uuid4();
    const assistantMessage = assistantMessagePromise
      .then((response) => {
        if (!response.ok || response.body === null) {
          throw new Error("Couldn't get message from gateway");
        }
        const [stream1, stream2] = response.body.tee();
        setStream(stream1);
        return readMessageStream(stream2, updateStreamedMessageText);
      })
      .then((text): IChatMessage => {
        return {
          role: MessageRole.Assistant,
          content: text,
          id: newId,
          conversationId: conversationId ?? "",
          updated: new Date(),
          model: conversationSettings.model,
        };
      })
      .catch((_) => {
        return {
          id: newId,
          conversationId: conversationId ?? "",
          role: MessageRole.Assistant,
          content: streamedContentRef.current,
          updated: new Date(),
          model: conversationSettings.model,
        };
      });

    onMessageSending();
    assistantMessage.then((assistantMessageReceived) => {
      db.chatMessage.add(assistantMessageReceived).then(onMessageSent);
      if (sp) sp.end();
      if (tr) tr.end();
    });
  };

  const handleMessageSent = useCallback<ComposeAreaProps["onSend"]>(
    async (newMessage) => {
      let tr = apm.startTransaction("generate", "llm.message");
      let sp = tr?.startSpan("message");
      streamedContentRef.current = "";
      if (chatMessages === undefined) {
        throw new Error("chatMessages is undefined");
      }
      const assistantMessagePromise =
        conversationSettings.model !== Model.Dalle3
          ? sendGatewayRequest(
              [...chatMessages, newMessage],
              conversationSettings.model,
              accessToken,
              conversationSettings.rag,
              conversationSettings.pii,
              handleSnackbarOpen,
            )
          : sendImageGatewayRequest(
              [...chatMessages, newMessage],
              conversationSettings.model,
              accessToken,
              conversationSettings.rag,
              conversationSettings.pii,
              handleSnackbarOpen,
            );
      abortControllerRef.current = assistantMessagePromise.abort;
      const newId = uuid4();
      const assistantMessage = assistantMessagePromise
        .then((response) => {
          if (!response.ok || response.body === null) {
            throw new Error("Couldn't get message from gateway");
          }
          const [stream1, stream2] = response.body.tee();
          setStream(stream1);
          return readMessageStream(stream2, updateStreamedMessageText);
        })
        .then((text): IChatMessage => {
          return {
            id: newId,
            conversationId: conversationId ?? "",
            role: MessageRole.Assistant,
            content: text,
            updated: new Date(),
            model: conversationSettings.model,
          };
        })
        .catch((_) => {
          return {
            id: newId,
            conversationId: conversationId ?? "",
            role: MessageRole.Assistant,
            content: streamedContentRef.current,
            updated: new Date(),
            model: conversationSettings.model,
          };
        });
      onMessageSending();
      await db.chatMessage.add(newMessage);
      assistantMessage.then((assistantMessageReceived) => {
        db.chatMessage.add(assistantMessageReceived).then(onMessageSent);
        if (sp) sp.end();
        if (tr) tr.end();
      });
    },
    [
      accessToken,
      chatMessages,
      conversationId,
      onMessageSending,
      onMessageSent,
      conversationSettings,
    ],
  );

  const handleConversationSettingsOpen = () => {
    setConversationSettingsOpen(true);
  };

  const handleConversationSettingsClose = () => {
    setConversationSettingsOpen(false);
  };

  const handleConversationSettingsSave = (
    newSettings: IConversationSettings,
  ) => {
    setConversationSettings(newSettings);
  };

  const handleExportTranscript = () => {
    onTranscriptDownloaded(chatMessages ?? []);
  };

  const cancelStream = useCallback(() => {
    setStream(undefined);
    clearSending();
    if (abort != null) {
      try {
        abort();
      } catch (error) {
        console.log("Aborted.");
      }
    }
  }, [clearSending, abort]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (notificationText: string) => {
    setSnackbarText(notificationText);
    setSnackbarOpen(true);
  };

  const composeBarHeight = 80;

  async function getNamed(id: string): Promise<boolean | undefined> {
    try {
      const conversation = await db.conversationInfo.get(id);
      return conversation?.named;
    } catch (error) {
      console.error("Failed to fetch named value:", error);
      return undefined;
    }
  }

  useEffect(() => {
    if (conversationId && chatMessages && chatMessages.length > 1) {
      (async () => {
        const isNamed = await getNamed(conversationId);
        if (!isNamed) {
          try {
            const response = await nameConversation(
              chatMessages.slice(1) ?? [],
              conversationSettings.model,
              accessToken,
            );
            if (response.ok) {
              const result = await response.json();
              let updatedName: string =
                result["choices"][0]["message"]["content"];
              if (updatedName.includes(": ")) {
                updatedName = updatedName.split(": ")[1];
              }
              updatedName = updatedName.replaceAll('"', "");
              const now = new Date();
              await db.conversationInfo.put({
                id: conversationId,
                name: updatedName,
                updated: now,
                named: true,
              });
            } else {
              console.error("Failed to automatically name the conversation");
            }
          } catch (error) {
            console.error(
              "Error in automatically naming the conversation:",
              error,
            );
          }
        }
      })();
    }
  }, [chatMessages]);

  if (conversationId == null) {
    return (
      <Alert
        severity="info"
        sx={{ ml: "auto", mr: "auto", mt: "1em", width: "fit-content" }}
      >
        Select or add a conversation to begin.
      </Alert>
    );
  }

  return (
    <UserContext.Provider value={{ userName: userName, roles: roles }}>
      <Toolbar />
      <Box ref={containerRef} component="div">
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarText}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          action={
            <Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleSnackbarClose}
              >
                <Close />
              </IconButton>
            </Fragment>
          }
        />
        <ChatHistory
          chatMessages={chatMessages ?? []}
          stream={stream}
          isSending={isSending}
          onRegenerateMessage={handleRegenerateMessage}
          scrollCallback={scrollToBottom}
          heightOffset={composeBarHeight}
          model={conversationSettings.model}
        />
        <Box
          position="sticky"
          component="div"
          sx={{
            boxSizing: "border-box",
            minHeight: composeBarHeight,
            maxHeight: `calc(${composeBarHeight}px * 4)`,
            bottom: 0,
            right: 0,
            margin: 0,
            backgroundColor: darkMode ? "black" : "white",
            overflow: "none",
          }}
        >
          <ComposeArea
            onSend={handleMessageSent}
            enabled={true}
            isSending={isSending}
            onConversationSettingsOpen={handleConversationSettingsOpen}
            onExportTranscript={handleExportTranscript}
            onError={handleSnackbarOpen}
            conversationId={conversationId}
            cancelStream={cancelStream}
          />
        </Box>
        <ConversationSettings
          open={conversationSettingsOpen}
          onClose={handleConversationSettingsClose}
          onSave={handleConversationSettingsSave}
          currentRag={conversationSettings.rag}
          currentPii={conversationSettings.pii}
          currentModel={conversationSettings.model}
          currentSystemPrompt={conversationSettings.systemPrompt}
        />
      </Box>
    </UserContext.Provider>
  );
}
